<template>
    <ValidationObserver ref="withdrawalRequestForm">
        <b-container class="mt-5">
            <b-row>
                <b-col>
                    <b-card class="p-5">
                        <b-row class="text-center">
                            <b-col cols="12" class="d-flex align-items-center justify-content-between mb-3">
                                <img src="../../../assets/img/BAU_Logo.png" height="75" alt="BAU Logo"/>
                                <h6 class="mb-0 bg-light" v-html="$t('bau_inspiration_text')"></h6>
                            </b-col>
                            <b-col>
                                <h3 class="mt-3 font-weight-bold">{{ $t('course_withdrawal_request') }}</h3>
                            </b-col>
                        </b-row>
                        <b-row class="mt-5">
                            <b-col cols="12">
                                <p v-html="$t('course_withdrawal_info')"></p>
                            </b-col>
                            <b-col cols="12">
                                <div v-html="$t('withdraw_request_policy_list')"></div>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group :label="$t('student_name')">
                                    <b-input-group>
                                        <div class="label-as-input">{{ isNotNullValue(form.student_name) }}</div>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                                <b-form-group :label="$t('telephone')">
                                    <b-input-group>
                                        <div class="label-as-input">{{ isNotNullValue(form.phone) }}</div>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                                <b-form-group :label="$t('email')">
                                    <b-input-group>
                                        <div class="label-as-input">{{ isNotNullValue(form.email) }}</div>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="4">
                                <b-form-group :label="$t('student_id')">
                                    <b-input-group>
                                        <div class="label-as-input">{{ isNotNullValue(form.student_id) }}</div>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr/>
                        <b-row>
                            <b-col cols="12">
                                <b-form-group :label="$t('program_of_study')">
                                    <b-input-group>
                                        <div class="label-as-input">{{ isNotNullValue(form.program_of_study) }}</div>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr/>
                        <b-row>
                            <b-col cols="12">
                                <ValidationProvider name="reason" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('reason_for_withdrawing')">
                                        <b-form-textarea v-model="form.reason"
                                                         :class="errors[0] ? 'is-invalid':''"
                                                         rows="5"
                                        />
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <hr/>
                        <b-row class="pb-5">
                            <b-col cols="12">
                                <p>
                                    {{ $t('withdraw_request_official_notice') }}
                                    <br/><br/>
                                    <span class="ml-5">{{ $t('withdraw_request_policy_acknowledgment') }}</span>
                                </p>
                            </b-col>
                        </b-row>
                        <b-row class="justify-content-between text-center my-5">
                            <b-col md="6" class="text-center mt-5">
                                <label class="col-form-label">{{ $t('student_signature') }}</label>
                                <p>{{ form.student_name }}</p>
                            </b-col>
                            <b-col md="6" class="text-center mt-5">
                                <label class="col-form-label">{{ $t('date') }}</label>
                                <p>{{ currentDate }}</p>

                            </b-col>
                        </b-row>
                        <b-button class="float-right my-5" @click="sendForm" variant="primary">{{
                                $t('send')
                            }}
                        </b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </ValidationObserver>
</template>
<script>
import WithDrawService from "@/services/WithDrawService";
import moment from "moment/moment";

export default {
    data() {
        return {
            student_course_id: this.$route.params.id,
            form: {
                email: null,
                student_id: null,
                student_name: null,
                reason: null,
                course_number_and_name: null,
                program_of_study: null,
                number_of_credits: null,
                phone: null,
            },
        };
    },
    metaInfo() {
        return {
            title: this.$t("course_withdrawal_request"),
        };
    },
    created() {
        this.getWithDrawRequestFormPreview();
    },
    computed: {
        currentDate() {
            return moment(new Date()).format("MM/DD/YYYY")
        }
    },
    methods: {
        getWithDrawRequestFormPreview() {
            let formData = {
                student_course_id: this.student_course_id
            }
            WithDrawService.getWithDrawRequestFormPreview(formData)
                .then(response => {
                    this.form = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(e.data.message)
                })
        },
        async sendForm() {
            if (this.checkPermission("withdraw_store")) {
                const isValid = await this.$refs.withdrawalRequestForm.validate();
                if (isValid) {
                    this.$swal.fire({
                        text: this.$t('are_you_sure_to_withdraw'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    }).then((result) => {
                        if (result.isConfirmed) {
                            let formData = {
                                student_course_id: this.student_course_id,
                                reason: this.form.reason,
                            };
                            WithDrawService.store(formData).then(res => this.$toast.success(this.$t("api." + res.data.message))).catch(e => this.showErrors(e))
                        }
                    })
                }
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },

    },
};
</script>
<style scoped>
hr {
    background: #cab385;
    margin: 20px 0;
    border-radius: 5px;
    box-shadow: 0 1px #fffdd4;
}

.bg-light {
    background-color: #e6e6e6;
}
</style>
